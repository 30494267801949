
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import roc_list, {
  business_natures,
  group_types,
  industry_types_pc,
  business_types,
} from "@/core/data/genericData";
import axios from "axios";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "add-client-modal",
  directives: { mask },
  components: {},

  setup() {
    const store = useStore();
    // const nob_data = business_natures;
    // const group_type_data = group_types;
    // const business_types_data = business_types;
    // const roc_list_data = roc_list;
    const group_type_data = ref([]);
    const nob_data = ref([]);
    const business_types_data = ref([]);
    const roc_list_data = ref([]);

    const industry_types_data = industry_types_pc;
    const formRef = ref<null | HTMLFormElement>(null);
    const addCompanyModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const router = useRouter();

    getAddData();

    // console.log("industry_types_data");console.log(industry_types_data);

    // interface Iind_ty_pc  {
    //   industry_type_name,
    //   industry_type_id,
    //   parent_industry_id
    // }

    // var industry_types_data = ref<Array<Iind_ty_pc>>([]);
    // var ind_ty_pcT = ref<Array<Iind_ty_pc>>([])
    // var k =  0;

    // for (let j = 0; j < industry_types_pc.length; j++) {

    //     ind_ty_pcT.value = Array({
    //       industry_type_name : industry_types_pc[j]['company_industry_type_name'],
    //       industry_type_id : industry_types_pc[j]['company_industry_type_id'],
    //       parent_industry_id : 0
    //     });

    //   industry_types_data.value.splice(k, ind_ty_pcT.value.length, ...ind_ty_pcT.value);

    //   if(industry_types_pc[j]['subtype']){

    //     console.log(industry_types_pc[j]['subtype']);

    //     for (let i = 0; i < industry_types_pc[j]['subtype'].length; i++) {
    //       ind_ty_pcT.value = Array({
    //         industry_type_name :  industry_types_pc[j]['subtype'][i]['company_industry_type_name'],
    //         industry_type_id :  industry_types_pc[j]['subtype'][i]['company_industry_type_id'],
    //         parent_industry_id : industry_types_pc[j]['subtype'][i]['company_industry_type_id'],
    //       });
    //       k = k+1;
    //       industry_types_data.value.splice(k, ind_ty_pcT.value.length, ...ind_ty_pcT.value);
    //     }

    //   }
    //   k = k+1;
    // }

    const formData = ref({
      name: "",
      alternativename: "",
      nob_select: "",
      group_type_select: "",
      industry_type_select: "",
      business_type_select: "",
      incorporationateDate: "",
      pan: "",
      cinn: "",
      llpinn: "",
      registrationNo: "",
      roc_select: "",
      website: "",
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: "Company Name is required",
          trigger: "change",
        },
      ],
      nob_select: [
        {
          required: true,
          message: "Nature of Business is required",
          trigger: "change",
        },
      ],
      group_type_select: [
        {
          required: true,
          message: "Group Type is required",
          trigger: "change",
        },
      ],
      industry_type_select: [
        {
          required: true,
          message: "Industry Type is required",
          trigger: "change",
        },
      ],
      business_type_select: [
        {
          required: true,
          message: "Business Type is required",
          trigger: "change",
        },
      ],
      incorporationateDate: [
        {
          required: true,
          message: "Date is required",
          trigger: "change",
        },
      ],
      pan: [
        {
          required: true,
          message: "PAN is required",
          trigger: "change",
          min: 10,
          max: 10,
        },
      ],
      cinn: [
        {
          required: true,
          message: "CIN / LLPIN is required",
          trigger: "change",
          min: 21,
          max: 21,
        },
      ],
      llpinn: [
        {
          required: true,
          message: "CIN / LLPIN is required",
          trigger: "change",
          min: 8,
          max: 8,
        },
      ],
      registrationNo: [
        {
          required: true,
          message: "Registration No is required",
          trigger: "change",
          min: 6,
          max: 6,
        },
      ],
      roc_select: [
        {
          required: true,
          message: "ROC is required",
          trigger: "change",
        },
      ],
    });

    const setCompanyData = async (data) => {
      var user = JSON.parse(JwtService.getToken());

      var dt = ("0" + data.incorporationateDate.getDate()).slice(-2);
      var mo = ("0" + (data.incorporationateDate.getMonth() + 1)).slice(-2);
      var yy = data.incorporationateDate.getFullYear();
      var dtt = yy + "-" + mo + "-" + dt;
      var cinLlpin = data.llpinn === "" ? data.cinn : data.llpinn;

      const db_data = {
        company_id:0,  
        company_name: data.name,
        company_name_alternative: data.alternativename,
        company_nob: data.nob_select,
        company_group_type: data.group_type_select,
        company_industry_type: data.industry_type_select,
        company_business_type: data.business_type_select,
        company_roc: data.roc_select === "" ? 0 : data.roc_select,
        company_doi: dtt,
        company_cin_llpin: cinLlpin === "" ? 0 : cinLlpin,
        company_registration_number:
          data.registrationNo === "" ? 0 : data.registrationNo,
        company_pan: data.pan,
        company_website: data.website,
        company_verified_yes_no: false,
        active: true,
        user_id: user.user_id,
        company_trade_type_id:1,
      };

      console.log(db_data);

      await store
        .dispatch(Actions.CUST_ADD_COMPANY, db_data)
        .then(({ data }) => {
          console.log(data);

          if (data.is_success) {
            setTimeout(() => {
              loading.value = false;
              //
              Swal.fire({
                text: "Company has been successfully created.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addCompanyModalRef.value);
                router.go(0);
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setCompanyData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    async function getAddData() {
      await store
        .dispatch(Actions.CUST_GET_COMPANY_PAGE_DATA)
        .then(({ body }) => {
          nob_data.value = body.business_natures;
          group_type_data.value = body.group_types;
          group_type_data.value.splice(0, 1);
          business_types_data.value = body.business_types;
          roc_list_data.value = body.roc_list;
          roc_list_data.value.splice(0, 1);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    return {
      group_type_data,
      industry_types_data,
      business_types_data,
      roc_list_data,
      formData,
      nob_data,
      rules,
      submit,
      formRef,
      loading,
      addCompanyModalRef,
    };
  },
});
